export const useWareBadges = () => {

  const extractLinkForTextBadge = (text) => {

    const cache = useCache();

    const result = {
      cleanText: text,
      link: null
    };

    const indexOfBracket = text.indexOf('{');

    if (indexOfBracket > -1) {
      result.cleanText = text.substring(0, indexOfBracket);
      const pageId = text.substring(indexOfBracket + 1).replaceAll('}', '');

      if (!isNaN(pageId)) {
        const link = cache.getPageUrl(pageId);

        if (link) {
          result.link = link;
        }
      }
    }

    return result;
  }

  const getBadgesForWare = (wareItem, i18n) => {

    const appConfig = useAppConfig();
    const utils = useUtils();
    const locale = useLocale().getLocale();

    const now = new Date();

    const badges = [];

    const salesRaw = wareItem.tags.find(tag => tag.type === 'SALES')?.value;
    const sales = salesRaw ? JSON.parse(salesRaw).find(sale => sale.idCurrency == locale.currencyId) : null;

    //#region parameter based badges

    const enabledLabelValues = [
      appConfig.parameterlValues.label_news,
      appConfig.parameterlValues.label_soldato,
      appConfig.parameterlValues.label_nsn,
      appConfig.parameterlValues.label_blackFriday,
      appConfig.parameterlValues.internal_engraving,
    ];

    wareItem.properties.concat(wareItem.customProperties).forEach(property => {
      property.values.forEach(value => {

        // predefinedtext badges based on label parameter

        if (enabledLabelValues.includes(value.lValue)) {

          // soldato image badge
          if (value.lValue === appConfig.parameterlValues.label_soldato) {
            badges.push({
              position: 9,
              isImageBadge: true,
              text: value.value,
              imageUrl: '/img/badge-soldato.png?v=2',
              analyticsText: 'Doporučuje Soldato'
            });
          }

          // nsn image badge
          else if (value.lValue === appConfig.parameterlValues.label_nsn) {
            badges.push({
              position: 10,
              isImageBadge: true,
              text: value.value,
              imageUrl: '/img/badge-nato.png',
              analyticsText: 'NSN'
            });
          }

          // regular generic text badge
          else {
            badges.push({
              position: 1,
              isTextBadge: true,
              text: value.value,
              type: value.lValue === appConfig.parameterlValues.label_news ? 'NEWS' : undefined,
              lValue: value.lValue,
              analyticsText: value.value
            });
          }
        }

        // dynamic text badges

        else if (property.id === appConfig.parameterIds.textBadge) {

          const { cleanText, link } = extractLinkForTextBadge(value.value);

          badges.push({
            position: 13,
            isTextBadge: !link,
            isLinkBadge: !!link,
            text: cleanText,
            href: link,
            analyticsText: cleanText
          });
        }
      });
    })

    //#endregion

    // action (sale) - show only if all variants are not on clearance sale and all variants are on sale
    if ((!sales?.anyClearanceSale || !sales?.allClearanceSale) && sales?.maxSale && sales?.minSale) {
      badges.push({
        position: 1,
        isTextBadge: true,
        text: i18n ? i18n.t('Akce') + ' -' + sales?.maxSale.toFixed(0) + '%' : null, // however we show max sale value
        type: 'SALE',
        style: 'border-color: #de1d20; color: #de1d20;',
        analyticsText: 'Akce'
      });
    }

    // news - check possible duplicate with label parameter
    if (!badges.some(badge => badge.type === 'NEWS') && utils.addDaysToDate(wareItem.page?.publishedAt ?? wareItem.created, appConfig.thresholdForNewsBadgeInDays) >= now) {
      badges.push({
        position: 1,
        isTextBadge: true,
        text: i18n ? i18n.t('Novinka') : null,
        type: 'NEWS',
        style: 'border-color: #81613c; color: #81613c;',
        analyticsText: 'Novinka'
      });
    }

    // new color - don´t show when there is already "news" badge
    if (wareItem.tags.some(tag => tag.type === 'NEWVARIANTGROUP')) {
      badges.push({
        position: 1,
        isTextBadge: true,
        text: i18n ? i18n.t('Nová barva') : null,
        type: 'NEW_COLOR',
        style: 'border-color: #81613c; color: #81613c;',
        analyticsText: 'Nová barva'
      });
    }

    // video
    if (wareItem.youtubeVideos?.some(ytvideo => ytvideo.isOurChannel)) {
      badges.push({
        position: 1,
        isTextBadge: true,
        text: i18n ? i18n.t('Video') : null,
        style: 'border-color: #a99146; color: #a99146;',
        analyticsText: 'Video'
      });
    }

    // free shipping
    if (wareItem.shippingOptions?.cheapest?.price === 0) {
      badges.push({
        position: 1,
        isTextBadge: true,
        text: i18n ? i18n.t('Doprava zdarma') : null,
        type: 'FREE_SHIPPING',
        style: 'border-color: #3b75af; color: #3b75af;',
        analyticsText: 'Doprava zdarma'
      });
    }

    // clearance sale
    if (!badges.some(badge => badge.type === 'SALE') && sales?.allClearanceSale && sales?.minSale) {
      badges.push({
        position: 1,
        isTextBadge: true,
        text: i18n ? i18n.t('Výprodej') + ' - ' + sales?.maxSale.toFixed(0) + '%' : null,
        style: 'border-color: #de1d20; color: #de1d20;',
        analyticsText: 'Výprodej'
      });
    }

    // preorder
    if (useBusiness().isPreorder(wareItem)) {
      badges.push({
        position: 1,
        isTextBadge: true,
        text: i18n ? i18n.t('Předobjednávka') : null,
        style: 'border-color: #e7c407; color: #e7c407;',
        analyticsText: 'Předobjednávka'
      });
    }

    return badges;
  }

  return {
    getBadgesForWare
  }
}